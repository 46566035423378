import React from 'react';
import {SiteMeta} from '../general/seo'
import {TopBar, BottomBar } from '../general/extremeBars'
import NavBar from '../general/navbar'
import ClientGrid from './grid';
import { graphql } from 'gatsby';


export const result = graphql`
query {
    datalogsJson {
      client_page {
        content {
          company_name
          description
          link
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
  }  
`


export function ClientPage ({data}){

    const PageData = data.datalogsJson.client_page;

    return (
    <>
    <SiteMeta title="Our Clients - Psaltry International Company Limited" description="Official Psaltry Company Information"/>
    <div className='dark:bg-gray-800'>
    <TopBar />
    <NavBar />
    <div className="md:mx-32 mx-2 sm:mx-10 lg:mx-32">
    <div role="heading" className="block my-10 ml-10 md:ml-0 w-fit lg:ml-0 xl:ml-0 focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">{PageData.title}
    <div className="border-b-4 border-sgreen w-8" />
    </div> 
    {PageData.content.map((body, index) => {
        return (<>
            <ClientGrid data={body} />
        </>)
    })}      
    </div>
    <BottomBar />
    </div>
    </>
    );
}


export default ClientPage;