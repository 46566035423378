import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";



export default function CientGrid ({data}){
    return (
        <>
        <div className="md:flex md:mx-auto items-center mb-20 w-full mx-0 container py-8 px-10 sm:px-10 md:px-20 shadow-lg bg-gray-50 dark:bg-gray-300">
            <div className="xl:w-1/3 md:w-1/3 w-full pl-14 md:pl-20 sm:pl-16">
            <GatsbyImage 
            image={data.logo.childImageSharp.gatsbyImageData}
            alt="Logo" 
            className="mx-auto"           
            />
            </div>
            <div className="xl:w-2/3 md:w-2/3 w-full">
            <div className="text-sgreen font-bold text-lg sm:text-2xl md:text-3xl lg:text3xl my-6 px-4">{data.company_name}</div>
            {data.description.map((body, index) => {
                return (
                    <>
                    <p className="text-md mb-8 px-4 text-justify">{body}</p>
                    </>
                );
            })}
            <a href={data.link} target="_blank" className="px-4 text-blue-900">Learn more</a>
            </div>
        </div>
        </>
    );
}